<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.minStayForm"
    />
    <h3 class="pb-2" v-if="!addingRule">
      Minimum Stay
      <hr />
    </h3>
    <button
      class="btn btn-primary mb-3"
      v-if="!addingRule"
      @click="addingRule = true"
    >
      Add New Rule
      <i class="fa fa-plus-circle" />
    </button>
    <div v-if="addingRule" class="card">
      <div class="card-header">
        <div class="card-title mb-0">Minimum Stay</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="minStayForm">
          <form @submit.prevent="onSubmit">
            <AdminCommonBusinessRuleFields
              :rule="minStayRule"
              :referenceId="referenceId"
            />
            <hr />
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,365"
                  name="Length"
                  id="ruleLength"
                  placeholder="Enter length"
                  v-model="minStayRule.minimumStayLength"
                />
                <small class="form-text text-muted mb-2"
                  >Minimum units of time allowed in a single reservation.</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Unit"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="ruleUnit"
                  >
                    Unit
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="ruleUnit"
                    v-model="minStayRule.unitId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(choice, index) in unitChoices"
                      :key="index"
                      :value="choice.id"
                    >
                      {{ choice.name }}
                    </option>
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Units of time to apply to length.</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Monday Length"
                  id="ruleMondayLength"
                  placeholder="Enter Monday length"
                  v-model="minStayRule.minimumStayMonday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Monday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Tuesday Length"
                  id="ruleTuesdayLength"
                  placeholder="Enter Tuesday length"
                  v-model="minStayRule.minimumStayTuesday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Tuesday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Wednesday Length"
                  id="ruleWednesdayLength"
                  placeholder="Enter Wednesday length"
                  v-model="minStayRule.minimumStayWednesday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Wednesday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Thursday Length"
                  id="ruleThursdayLength"
                  placeholder="Enter Thursday length"
                  v-model="minStayRule.minimumStayThursday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Thursday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Friday Length"
                  id="ruleFridayLength"
                  placeholder="Enter Friday length"
                  v-model="minStayRule.minimumStayFriday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Friday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Saturday Length"
                  id="ruleSaturdayLength"
                  placeholder="Enter Saturday length"
                  v-model="minStayRule.minimumStaySaturday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Saturday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:0,7"
                  name="Sunday Length"
                  id="ruleSundayLength"
                  placeholder="Enter Sunday length"
                  v-model="minStayRule.minimumStaySunday"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Sunday.
                </small>
              </div>
              <div class="col-md-6 col-sm-12"></div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:1,9"
                  name="Memorial Day Length"
                  id="ruleHolidayLength"
                  placeholder="Enter Memorial Day length"
                  v-model="minStayRule.minimumStayMemorialDay"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Memorial Day.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:1,9"
                  name="July 4th Length"
                  id="ruleHolidayLength"
                  placeholder="Enter July 4th length"
                  v-model="minStayRule.minimumStayJuly4"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a July 4th.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:1,9"
                  name="Labor Day Length"
                  id="ruleHolidayLength"
                  placeholder="Enter Labor Day length"
                  v-model="minStayRule.minimumStayLaborDay"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Labor Day.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="between:1,9"
                  name="Columbus Day Length"
                  id="ruleHolidayLength"
                  placeholder="Enter Columbus Day length"
                  v-model="minStayRule.minimumStayColumbusDay"
                />
                <small class="form-text text-muted mb-2">
                  Minimum units of time allowed in a single reservation starting
                  on a Columbus Day.
                </small>
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-3">
              Submit
            </button>
            <button
              class="btn btn-cancel btn-lg mt-3 ml-1"
              @click="addingRule = false"
              type="button"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <AdminBusinessRuleTable
      :tableData="tableData"
      @viewRule="viewRule"
      @editRule="editRule"
      :uniqueFields="uniqueFields"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields";
import AdminBusinessRuleTable from "@/components/admin/AdminBusinessRuleTable";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminMinimumStay",
  title: "Admin - Minimum Stay",
  components: {
    ValidationObserver,
    ValidationProvider,
    AdminCommonBusinessRuleFields,
    AdminBusinessRuleTable,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      minStayRule: {
        name: "Minimum Stay",
        isActive: true,
        productId: "",
        productTypeId: "",
        areaId: "",
        loopId: "",
        seasonTypeId: "",
        salesChannelId: "",
        paymentTypeId: "",
        unitId: "1"
      },
      tableData: [],
      unitChoices: [],
      errors: [],
      uniqueFields: [
        { key: "minimumStayLength", sortable: true },
        { key: "unit", sortable: true }
      ],
      addingRule: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    referenceId() {
      return `D${(this.tableData.length + 1 + "").padStart(3, "0")}`;
    }
  },
  methods: {
    async loadData() {
      const businessRuleService = new AdminBusinessRuleService(this.tenantId);
      businessRuleService.getAllMinimumStay().then(response => {
        this.tableData = response.data.map((x, index) => {
          return { ...x, referenceId: `D${(index + 1 + "").padStart(3, "0")}` };
        });
      });
    },
    async initialize() {
      const dataService = new AdminLookupService(this.tenantId);
      dataService.getAllUnits().then(response => {
        this.unitChoices = response;
      });
      this.loadData();
    },
    async onSubmit() {
      this.$refs.minStayForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.minStayForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.minStayForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          //Map to request object
          var request = {
            isActive: this.minStayRule.isActive,
            productId: this.minStayRule.productId,
            productTypeId: this.minStayRule.productTypeId,
            areaId: this.minStayRule.areaId,
            loopId: this.minStayRule.loopId,
            salesChannelId: this.minStayRule.salesChannelId,
            seasonTypeId: this.minStayRule.seasonTypeId,
            paymentTypeId: this.minStayRule.seasonTypeId,
            comments: this.minStayRule.comments,
            startDate: this.minStayRule.startDate,
            endDate: this.minStayRule.endDate,
            minimumStayLength: this.minStayRule.minimumStayLength,
            unitId: this.minStayRule.unitId,
            minimumStayMonday: this.minStayRule.minimumStayMonday,
            minimumStayTuesday: this.minStayRule.minimumStayTuesday,
            minimumStayWednesday: this.minStayRule.minimumStayWednesday,
            minimumStayThursday: this.minStayRule.minimumStayThursday,
            minimumStayFriday: this.minStayRule.minimumStayFriday,
            minimumStaySaturday: this.minStayRule.minimumStaySaturday,
            minimumStaySunday: this.minStayRule.minimumStaySunday,
            minimumStayMemorialDay: this.minStayRule.minimumStayMemorialDay,
            minimumStayJuly4: this.minStayRule.minimumStayJuly4,
            minimumStayLaborDay: this.minStayRule.minimumStayLaborDay,
            minimumStayColumbusDay: this.minStayRule.minimumStayColumbusDay,
            validationMessage: this.minStayRule.validationMessage
          };
          if (this.minStayRule.productClasses) {
            request.productClassIds = this.minStayRule.productClasses.map(
              n => n.id
            );
          }
          const businessRuleService = new AdminBusinessRuleService(
            this.tenantId
          );
          var response = await businessRuleService.addMinimumStay(request);
          if (response.statusCode == "Success") {
            //Reload data for grid
            this.loadData();
            this.minStayRule = {
              name: "Minimum Stay",
              isActive: true,
              productId: "",
              productTypeId: "",
              areaId: "",
              loopId: "",
              seasonTypeId: "",
              salesChannelId: "",
              paymentTypeId: "",
              unitId: "1"
            };
            this.addingRule = false;
            window.scrollTo(0, 0);
          }
        }
      });
    },
    viewRule(item) {
      this.$router
        .push(
          `/admin/brm/minimum-stay/${item.id}?refId=${item.referenceId}&v=true`
        )
        .catch(() => {});
    },
    editRule(item) {
      this.$router
        .push(
          `/admin/brm/minimum-stay/${item.id}?refId=${item.referenceId}&v=false`
        )
        .catch(() => {});
    }
  },
  created() {
    this.initialize();
  }
};
</script>
